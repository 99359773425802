<template>
    <div>
        <div class="detail">
            <div class="search">
                <span @click="handleToManage">活动列表</span> / <span>{{$route.params.id == 'new' ? '新增' : '编辑'}}活动</span>
            </div>
            <a-form :form="form" class="form" :label-col="{ span: 2}" :wrapper-col="{ span: 12 }">
                <a-form-item label="资讯名称">
                    <a-input
                        autocomplete="off"
                        v-decorator="['name']"
                        placeholder="50字以内"
                    />
                </a-form-item>
                <a-form-item label="发布日期">
                    <a-date-picker v-model="date" :showToday="false" :disabled-date="disabledDate" :locale="locale" @change="handleChange" />
                </a-form-item>
                <a-form-item label="发布社区">
                    <a-select
                        v-decorator="[
                            'community_id'
                            ]"
                        placeholder="请选择社区"
                        showSearch
                        option-filter-prop="children"
                        :filter-option="filterOption"
                        >
                        <a-select-option
                            v-for="(item, index) in communities"
                            :key="index"
                            :value="item.id"
                        >{{item.name}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="资讯缩略图">
                    <span class="redFont">支持图片格式：PNG、JPG、GIF，10M以内，推荐图标尺寸64*64px</span>
                    <a-upload
                        name="img"
                        list-type="picture-card"
                        :show-upload-list="false"
                        :before-upload="beforeUpload"
                    >
                        <img v-if="previewImage" :src="previewImage" class="icon" alt="avatar" />
                        <div v-else>
                            <a-icon :type="loading ? 'loading' : 'plus'" />
                            <div class="ant-upload-text">点击上传</div>
                        </div>
                    </a-upload>
                </a-form-item>
                <a-form-item label="内容形式">
                    <a-radio-group @change="onRadioChange" v-decorator="['radio']">
                        <a-radio :checked="checked" value="1">
                        链接地址
                        </a-radio>
                        <a-radio :checked="!checked" value="2">
                        自定义详情
                        </a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item v-if="isShow" label="链接地址">
                    <a-input
                        autocomplete="off"
                        v-decorator="['link_addr']"
                        placeholder=""
                    />
                </a-form-item>
                <a-form-item v-else label="自定义详情">
                    <div class="wangeditor-box" ref="editorE1" style="text-align:left;min-height: 410px;"></div>
                </a-form-item>
                <div class="bth">
                    <a-button :disabled="isDisabled" @click="handleSubmit(true)">保存并发布</a-button>
                    <a-button :disabled="isDisabled" :style="{ marginLeft: '20px' }" @click="handleSubmit(false)">保存但不发布</a-button>
                    <a-button :style="{ marginLeft: '20px' }">预览</a-button>
                </div>
            </a-form>
        </div>
    </div>
</template>

<script>
import { ManageModel } from "../../model/manage";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import OSS from 'ali-oss';
moment.locale('zh-cn')
import E from "wangeditor";
import upload from '../../utils/oss'
// import formatTime from './../../utils/formatTime'
const manageModel = new ManageModel();

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
    data() {
        return {
            form: this.$form.createForm(this, {
                name: "horizontal_login"
            }),
            locale,
            loading: false,
            previewVisible: false,
            previewImage: '',
            src: '',
            img: '',
            detail: '',
            date: '',
            isDisabled: false,
            isShow: false,
            checked: true,
            communities: [],
            linkAddr: ''
        }
    },

    created() {
        if(this.$route.params.id != 'new') {
            manageModel.getActivities({id: this.$route.params.id}).then(res => {
                if(res.code == '000000') {
                    let data = res.data.filter(item => item.id == this.$route.params.id)
                    console.log(data)
                    this.$nextTick(() => {
                        this.form.setFieldsValue({
                            name: data[0].name,
                            radio: String(data[0].radio),
                            community_id: data[0].community_id
                        })
                    })
                    this.isShow = data[0].radio == 1 ? true : false,
                    this.date = data[0].apply_at,
                    this.img = this.previewImage = data[0].img
                    this.detail = data[0].detail
                    this.linkAddr = data[0].link_addr
                    setTimeout(() => {
                        this.form.setFieldsValue({
                            link_addr: data[0].link_addr,
                        })
                    })
                }
            }) 
        }  else {
            this.$nextTick(() => {
                this.form.setFieldsValue({
                    radio: '1',
                })
                this.isShow = true
            })
        }

        this._queryCommunities()
    },

    mounted() {
        setTimeout(() => {
            this.editor1 = new E(this.$refs.editorE1);
            this.editor1.config.showLinkImg = false
            let client = new OSS({
                accessKeyId: 'LTAIbW3TXatTSBZH',
                accessKeySecret: 'nHnSsGlWx8CKr92sQ0eJcuyLbmtxUW',
                region: 'oss-cn-qingdao', //
                bucket: 'tzwy-mobile',//
                secure: true,
            });

            this.editor1.config.customUploadImg = function (resultFiles, insertImgFn) {
                // resultFiles 是 input 中选中的文件列表
                // insertImgFn 是获取图片 url 后，插入到编辑器的方法
                client.put('img', resultFiles[0])
                .then(function (res) {
                    // 上传图片，返回结果，将图片插入到编辑器中
                    insertImgFn(res.url)
                }).catch(function (err) {
                    console.log(err)
                })
            }
            this.editor1.config.onchange = html => {
                this.detail = html;
            };
            
            this.editor1.create();
            this.editor1.txt.html(this.detail);
            // this.editor1.txt.html(this.introContent);
        }, 1000)

        // if($route.params.id == 'new')
    },

    methods: {
        handleToManage() {
            this.$router.push(`/manage`)
        },

        onRadioChange(e){
            console.log(e)
            this.isShow = e.target.value == '1' ? true : false
            if(e.target.value == '2') {
                setTimeout(() => {
                    this.editor1 = new E(this.$refs.editorE1);
                    this.editor1.config.showLinkImg = false
                    let client = new OSS({
                        accessKeyId: 'LTAIbW3TXatTSBZH',
                        accessKeySecret: 'nHnSsGlWx8CKr92sQ0eJcuyLbmtxUW',
                        region: 'oss-cn-qingdao', //
                        bucket: 'tzwy-mobile',//
                        secure: true,
                    });

                    this.editor1.config.customUploadImg = function (resultFiles, insertImgFn) {
                        // resultFiles 是 input 中选中的文件列表
                        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
                        client.put('img', resultFiles[0])
                        .then(function (res) {
                            // 上传图片，返回结果，将图片插入到编辑器中
                            insertImgFn(res.url)
                        }).catch(function (err) {
                            console.log(err)
                        })
                    }
                    this.editor1.config.onchange = html => {
                        this.detail = html;
                    };
                    
                    this.editor1.create();
                    this.editor1.txt.html(this.detail);
                }, 100)
            } else {
                this.$nextTick(() => {
                    this.form.setFieldsValue({
                        link_addr: this.linkAddr
                    })
                })
            }
        },

        handleChange(date, dateString) {
            console.log(date, dateString);
            this.date = dateString
        },

        disabledDate(current) {
			// Can not select days before today and today
			return current && current > moment().endOf('day')
		},

         async handleUploaderChange(info) {
            const {fileList} = info
            this.img = this.previewImage = await getBase64(fileList.pop().originFileObj)
        },

        beforeUpload(file) {
            this.loading = true
            upload(file, "tzwy-mobile", {}).then(res => {
                this.loading = false
                this.img = this.previewImage = res
            })
        },

        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },

        handleSubmit(flag) {
            this.isDisabled = true
            this.form.validateFields((err, values) => {
                if (!err) {
                    values.detail = this.detail
                    values.img = this.img
                    values.apply_at = this.date
                    values.apply = flag
                    values.radio = Number(values.radio)
                    if(this.$route.params.id == 'new') {
                        manageModel.postActivity(values).then(res => {
                            console.log(res)
                            if(res.code == '000000') {
                                this.$router.push(`/manage`)
                            } else {
                                this.$notification.error({
                                    message: "提示",
                                    description: res.message,
                                    duration: 2
                                })
                            }
                            this.isDisabled = false
                        }).catch(err => {
                            console.log(err)
                        })
                    } else {
                        values.id = this.$route.params.id
                        manageModel.updateActivity(values).then(res => {
                            console.log(res)
                            if(res.code == '000000') {
                                this.$router.push(`/manage`)
                            } else {
                                this.$notification.error({
                                    message: "提示",
                                    description: res.message,
                                    duration: 2
                                })
                            }
                            this.isDisabled = false
                        }).catch(err => {
                            console.log(err)
                        })
                    } 
                }
            })
        },

        _queryCommunities() {
            manageModel.getCommunities().then(res => {
                if(res.code == '000000') {
                    this.communities = res.data
                } else {
                    this.$notification.success({
                        message: "提示",
                        description: res.message,
                        duration: 2
                    })
                }
            }).catch(err => {
                console.log(err)
            })
        },

    }
}

</script>


<style scoped>
    .detail {
        padding: 15px;
    }

    .search {
        height: 50px;
        line-height: 50px;
        padding-left: 15px;
        box-shadow: 0 0  3px 3px #e6c2c2;
    }

    .search span:nth-of-type(1) {
        color: #20A0FF;
        margin-right: 5px;
        cursor: pointer;
    }

    .search span:nth-of-type(2) {
        color: #666;
        margin-left: 5px;
    }

    .ant-form {
        margin-top: 20px;
    }

    .form /deep/ .ant-input,
    .form /deep/ .ant-select,
    .form /deep/ .ant-calendar-picker {
        width: 500px;
    }

    .form /deep/ .ant-btn {
        border-color: #4DD493;
        background-color: #4DD493;
        color: #fff;
    }

    .bth {
        display: flex;
        justify-content: center;
    }

    .form /deep/ .ant-input:focus,
    .form /deep/ .ant-input:hover,
    .form /deep/ .ant-upload:focus,
    .form /deep/ .ant-upload:hover {
        border-color: #4DD493;
        box-shadow: 0 0 0 2px rgb(77 212 147 / 20%);
    }

    .form /deep/ .ant-form-item {
        margin-bottom: 15px !important;
    }

    .icon {
        width: 64px;
    }

</style>
